// lg
@mixin under-lg() {
  @media (max-width: ($lg - 1)+px) {
    @content;
  }
}
@mixin over-lg() {
  @media (min-width: $lg+px) {
    @content;
  }
}

// md
@mixin under-md() {
  @media (max-width: ($md - 1)+px) {
    @content;
  }
}
@mixin over-md() {
  @media (min-width: $md+px) {
    @content;
  }
}


// sm
@mixin under-sm() {
  @media (max-width: ($sm - 1)+px) {
    @content;
  }
}
@mixin over-sm() {
  @media (min-width: $sm+px) {
    @content;
  }
}

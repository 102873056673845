@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import '~factor/src/scss/elevation/mdc-elevation';
@import 'src/scss/components/mixins';

.param {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 2rem 1.5+rem;
  width: 100%;

  &.withBorder {
    border: 1/16+rem solid $table-row-hover;
  }

  &.borderBottom {

    @include under-md() {
      border-bottom: 1/16+rem solid $table-row-hover;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 10/16+rem;
  @include f1-400();
  font-size: 1rem;
  line-height: 19/16+em;
  color: $dove-gray;

  @include under-md() {
    font-size: 1.25rem;
  }
}

.tooltip {
  margin-left: .5rem;
  line-height: 0;

  svg {
    width: 1.25rem;
    height: auto;
  }
}

.value {
  margin-bottom: 1rem;
  @include f1-700();
  font-size: 1.5rem;
  line-height: 29/16+em;
  color: #000;

  @include under-md() {
    font-size: 2rem;
  }
}

.down {
  margin: auto -1.5rem -2rem;
  padding: 1rem 1.5rem;
  min-height: 96/16+rem;
  display: flex;
  align-items: center;
  background: $wild-sand;

  &.withButtons {
    padding: 1rem .5rem;

    .desired {
      display: flex;
      justify-content: center;
    }
  }
}

.desired {
  width: 100%;

  &:focus {
    outline: none;
  }

  :global {

    .btn-group {
      background: #fff;
    }
  }
}

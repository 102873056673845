@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.text {
  @include f1-400();
  font-size: 1rem;
  line-height: 17/14+em;
  color: #000;

  @include under-md() {
    font-size: 1.25rem;
  }
}

.title-4 {
  @include f1-400();
  font-size: 1rem;
  line-height: 19/16+em;
  color: $dove-gray;

  @include under-md() {
    font-size: 1.25rem;
  }
}

body {

  .select {
    max-width: 100%;
    width: auto;

    &._opened & {

      &__wrapper {
        z-index: 2;
      }
    }
  }
}

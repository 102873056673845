@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import 'src/scss/components/mixins';

.params {

  @include under-md() {
    margin-bottom: 1.75rem;
    border-bottom: .0625rem solid $table-row-hover;
  }
}

.title {
  margin-bottom: 1.5rem;
  text-align: center;
}

.item {
  display: flex;
  align-items: stretch;
  margin-bottom: 50/16+rem;

  @include under-md() {
    margin-bottom: 2.25rem;
  }
}

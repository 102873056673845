@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.title {
  display: flex;
  align-items: center;
  margin-bottom: 18/16+rem;
}

.tooltip {
  margin-left: .5rem;
  line-height: 0;

  svg {
    width: 1.25rem;
    height: auto;
  }
}

.row {
  display: flex;
  align-items: center;
}

.label {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: .0625rem;
    height: 1rem;
    background: $table-row-hover;
    top: 50%;
    transform: translateY(-50%);
  }

  &:first-child {
    padding-right: 1rem;

    &:before {
      right: 0;
    }
  }

  &:last-child {
    padding-left: 1rem;

    &:before {
      left: 0;
    }
  }
}

.bar {
  position: relative;
  flex: 1 1 auto;
  height: .25rem;
  background: $conflower-blue;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: .0625rem;
    height: 1rem;
    background: $table-row-hover;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 33.333%;
  }

  &:after {
    right: 33.333%;
  }
}

.fill {
  height: 100%;
  background: $table-row-hover;
}

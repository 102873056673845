body {

  .date-range-picker {

    &._opened {
      position: relative;
      z-index: 2;
    }

    &__wrapper-inner {

      @include under-sm() {
        left: 0;
        right: auto;
      }
    }
  }
}

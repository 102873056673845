@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import 'src/scss/components/mixins';

.main {
  padding: 0 2rem 1.625rem;
  background-image: linear-gradient(to bottom, $conflower-blue 0, $conflower-blue 363/16+rem, transparent 363/16+rem);

  @include under-md() {
    padding-left: 1rem;
    padding-right: 1rem;
    background-image: linear-gradient(to bottom, $conflower-blue 0, $conflower-blue 293/16+rem, transparent 293/16+rem);
  }
}

.header {
  display: flex;
  justify-content: center;
  padding: 2rem;
  min-height: 110/16+rem;
  background: $conflower-blue;
  @include f1-400();
  font-size: 2rem;
  line-height: 38/32+em;
  color: #fff;
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 1210/16+rem;
  border-radius: .5rem;
  border: 1/16+rem solid $table-row-hover;
  background: #fff;
  box-shadow: 0 0 .75rem 0 rgba(0, 0, 0, .1);
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80/16+rem;
  padding-bottom: 39/16+rem;

  &:not(:last-child) {
    border-bottom: 1/16+rem solid $table-row-hover;
    margin-bottom: 2rem;
  }

  :global(.row) {

    &:not(:last-child) {
      margin-bottom: 60/16+rem;
    }
  }

  @include under-md() {
    padding-top: 2.75rem;
    padding-bottom: 1.5rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

.select {

  &:not(:nth-last-child(-n+3)) {
    margin-bottom: 3.75rem;
  }

  @include under-md() {

    &:not(:nth-last-child(-n+2)) {
      margin-bottom: 3.75rem;
    }
  }

  @include under-sm() {

    &:not(:last-child) {
      margin-bottom: 2.75rem;
    }
  }

  &.creative,
  &.publisher {

    :global {

      .select {

        &._opened {

          .select__wrapper {
            min-width: 280/16+rem;
          }
        }
      }
    }
  }
}

.datepicker {

  :global {

    .date-range-picker {

      &,
      &__wrapper {
        width: 100%;
      }
    }

    .date-picker {
      width: 100%;

      &__wrapper {
        flex-grow: 0;
      }

      &__item-divider {
        margin: 0 .5rem;
      }
    }
  }
}

.showEstimate {
  margin-top: 3rem;
}

.bars {
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1/16+rem solid $table-row-hover;

  @include under-sm() {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.bar {

  @include under-sm() {

    &:not(:last-child) {
      margin-bottom: 2.25rem;
    }
  }
}

.contacts {
  margin-bottom: 2.5rem;
}

.download {
  margin: 0 auto .5rem;

  svg {
    margin-left: .5rem;
    width: 1rem;
    height: auto;

    @include under-md() {
      width: 1.25rem;
    }
  }
}

.comment {
  margin-bottom: 1.625rem;
  text-align: center;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 108/16+rem;

  :global(.logo) {
    width: 120/16+rem;
    height: auto;
  }
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import 'src/scss/components/mixins';

.contacts {

  @include under-sm() {
    margin-bottom: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: .0625rem solid $table-row-hover;
  }
}

.item {

  @include under-sm() {

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

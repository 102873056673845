* {
  box-sizing: border-box;
}

html {
  margin: 0;
  font-size: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container-1 {
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 832/16+rem;
}
